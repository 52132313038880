import React from "react";
import {
    BrowserRouter,
    Switch,
    Route,
} from "react-router-dom";
import Header from './Header';
import CraDefault from './CraDefault';
import DewPoint from "./DewPoint";
import TicTacToe from "./TicTacToe";

const AppRoot = () => {
    return (
        <BrowserRouter>
            <div>
                <Header />

                <Switch>
                    <Route path="/cra">
                        <CraDefault />
                    </Route>
                    <Route path="/tutorial">
                        <TicTacToe />
                    </Route>
                    <Route path="/dewpoint">
                        <DewPoint />
                    </Route>
                </Switch>
            </div>
        </BrowserRouter>
    );
}

export default AppRoot;
import React, { Component } from "react";
import axios from 'axios';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper'; // Paper is a Material UI surface type: https://material-ui.com/components/paper/

// Example based on https://www.andreasreiterer.at/rest-api-react-component/
export default class DewPoint extends Component {
    state = {
        error: null,
        isLoaded: false,
        weatherData: null,
        latitude: 0,
        longitude: 0,
        showFahrenheit: true,
        showCelsius: false,
    };

    componentDidMount() {
        this.setState({
            latitude: process.env.REACT_APP_LATITUDE,
            longitude: process.env.REACT_APP_LONGITUDE
        }, this.loadData);
    }

    render() {
        const { error, isLoaded, weatherData } = this.state;
        if (error) {
            return <div>Error: {error.message}</div>;
        } else if (!isLoaded) {
            return <div>Loading...</div>;
        } else {
            let hourlyData = [];
            const hourCount = weatherData.hourly.length;
            for(let i = 0; i < hourCount; i++) {
                let hour = weatherData.hourly[i];
                const forceFullDate = i === 0;

                let displayDewPoint = this.formatTemperatureForDisplay(this.calculateDewPoint(hour.temp, hour.humidity));
                let displayTemperature = this.formatTemperatureForDisplay(hour.temp);

                hourlyData[i] = {
                    dt: hour.dt,
                    time: this.convertEpochToSpecificTimezone(hour.dt, forceFullDate),
                    dewPoint: displayDewPoint,
                    temperature: displayTemperature,
                    humidity: hour.humidity + "%",
                };
            }

            const currentDewPoint = this.formatTemperatureForDisplay(this.calculateDewPoint(weatherData.current.temp, weatherData.current.humidity));
            const currentTemperature = this.formatTemperatureForDisplay(weatherData.current.temp);
            const currentHumidity = weatherData.current.humidity + "%";

            return (
                <div>
                    <div style={{float: "left", padding: "3em"}}>
                        <label htmlFor={"latitudeInput"}>Latitude </label>
                        <input type="number" id={"latitudeInput"} value={this.state.latitude} onChange={val => this.updateLatitude(val)} min={-90} max={90} step={0.01} />
                        &nbsp;&nbsp;&nbsp;
                        <label htmlFor={"longitudeInput"}>Longitude </label>
                        <input type="number" id={"longitudeInput"} value={this.state.longitude} onChange={val => this.updateLongitude(val)} min={-180} max={180} step={0.01} />
                        &nbsp;&nbsp;&nbsp;
                        <input type={"submit"} value={"Submit"} onClick={() => this.loadData()} />

                        <br /><br />

                        <p>Units (requires at least one, defaults to F)</p>
                        <FormControlLabel
                            control={<Checkbox checked={this.state.showFahrenheit} onChange={val => this.toggleFahrenheit(val)} />}
                            label="Fahrenheit"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={this.state.showCelsius} onChange={val => this.toggleCelsius(val)} />}
                            label="Celsius"
                        />

                    </div>

                    {/*Table based on default simple table from documentation: https://material-ui.com/components/tables/*/}

                    <div style={{width: "50em", float: "left", padding: "3em"}}>
                        <TableContainer component={Paper}>
                            <Table stickyHeader aria-label="Weather information table">
                                <colgroup>
                                    <col style={{width: '33%'}}/>
                                    <col style={{width: '25%'}}/>
                                    <col style={{width: '25%'}}/>
                                    <col style={{width: '17%'}}/>
                                </colgroup>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Date/Time</TableCell>
                                        <TableCell align="right">Dew point</TableCell>
                                        <TableCell align="right">Temperature</TableCell>
                                        <TableCell align="right">Humidity</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow key={1} style={{background: "#CCEECC"}}>
                                        <TableCell component="th" scope="row">Current</TableCell>
                                        <TableCell align="right">{currentDewPoint}</TableCell>
                                        <TableCell align="right">{currentTemperature}</TableCell>
                                        <TableCell align="right">{currentHumidity}</TableCell>
                                    </TableRow>
                                    {hourlyData.map((hourRow, index) => (
                                        <TableRow key={hourRow.dt} style={index % 2 ? {background: "white"} : {background: "#CCCCEE"}}>
                                            <TableCell component="th" scope="row">{hourRow.time}</TableCell>
                                            <TableCell align="right">{hourRow.dewPoint}</TableCell>
                                            <TableCell align="right">{hourRow.temperature}</TableCell>
                                            <TableCell align="right">{hourRow.humidity}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </div>
            );
        }
    }

    loadData() {
        if(process.env.NODE_ENV !== "production") {
            this.loadStaticData();
        } else {
            // OpenWeather API: https://openweathermap.org/api/one-call-api
            const apiKey = process.env.REACT_APP_WEATHER_API_KEY; // TODO: secure this by moving to server side
            let apiUrl = "https://api.openweathermap.org/data/2.5/onecall?lat=" + this.state.latitude + "&lon=" + this.state.longitude + "&exclude=minutely,alerts&appid=" + apiKey + "&units=metric";

            axios.get(apiUrl, {
                proxy: { // FIXME axios seems to be ignoring proxy setting entirely
                    host: 'localhost',
                    port: 8500
                }
            }).then(
                result => {
                    this.setState({
                        isLoaded: true,
                        weatherData: result.data
                    });
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                error => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            );
        }
    }

    updateLatitude(val) {
        this.setState({latitude: val.target.valueAsNumber});
    }

    updateLongitude(val) {
        this.setState({longitude: val.target.valueAsNumber});
    }

    toggleFahrenheit(val) {
        if(this.state.showCelsius) {
            this.setState({showFahrenheit: !this.state.showFahrenheit});
        }
    }

    toggleCelsius(val) {
        if(this.state.showCelsius && !this.state.showFahrenheit) {
            // Don't let both boxes be unchecked
            this.setState({showFahrenheit: true});
        }
        this.setState({showCelsius: !this.state.showCelsius});
    }

    formatTemperatureForDisplay(temperature) {
        let displayTemperature;

        if(this.state.showFahrenheit && this.state.showCelsius) {
            displayTemperature = this.celsiusToFahrenheit(temperature) + "°F (" + temperature + "°C)";
        } else if(this.state.showCelsius) {
            displayTemperature = temperature + "°C";
        } else {
            displayTemperature = this.celsiusToFahrenheit(temperature) + "°F";
        }

        return displayTemperature;
    }

    // https://cals.arizona.edu/azmet/dewpoint.html
    calculateDewPoint(temperatureCelsius, humidityPercent) {
        const L = Math.log(humidityPercent / 100);
        const M = 17.27 * temperatureCelsius;
        const N = 237.3 + temperatureCelsius;
        const B = (L + (M / N)) / 17.27;
        const D = (237.3 * B) / (1 - B);

        return Math.round(D * 10) / 10;
    }

    celsiusToFahrenheit(celsius) {
        return Math.round((celsius * 1.8 + 32) * 10) / 10;
    }

    // Based on https://stackoverflow.com/a/44062122 by Stack Overflow user Huiting
    convertEpochToSpecificTimezone(epochTime, forceFullDate) {
        const date = new Date((epochTime) * 1000);

        let dateToDisplay = "";
        let dateHumanReadableLong = date.toLocaleString(navigator.language, { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit'});
        const MIDNIGHT = "12 AM";
        const HOUR_MAX_LENGTH = 5; // Could be "1 PM" (four chars) or "12 AM" (five chars)

        if(forceFullDate || dateHumanReadableLong.includes(MIDNIGHT, dateHumanReadableLong.length - HOUR_MAX_LENGTH)) {
            dateToDisplay = dateHumanReadableLong;
        } else {
            dateToDisplay = dateHumanReadableLong.slice(-HOUR_MAX_LENGTH);
            dateToDisplay = dateToDisplay.trimStart(); // Since end can be four or five chars, last five might include a leading space
        }

        return dateToDisplay;
    }

    // For local dev/test, to not waste API calls
    async loadStaticData() {
        const staticResource = await fetch("/weatherStaticExample.json");
        const staticData = await staticResource.json(); // MUST use .json() and not .text()

        this.setState(
            {
                isLoaded: true,
                weatherData: staticData
            }
        );
    }
}

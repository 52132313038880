import React, { Component } from "react";
import {Link} from "react-router-dom";

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

export default class Header extends Component {
    render() {
        return (
            <div style={{background: "#888888"}}>
                <h1 style={{padding: "1em", color: "#FFFFFF"}}>Obverse Engineering</h1>

                <nav className="nav">
                    <AppBar position="static">
                        <Tabs value={false}>
                            <Link to={"/"}>
                                <Tab label="Home" style={{color: "#FFFFFF"}} />
                            </Link>
                            <Link to={"/cra"}>
                                <Tab label="Default CRA contents" style={{color: "#FFFFFF"}} />
                            </Link>
                            <Link to={"/tutorial"}>
                                <Tab label="Tic Tac Toe" style={{color: "#FFFFFF"}} />
                            </Link>
                            <Link to={"/dewpoint"}>
                                <Tab label="Dew point" style={{color: "#FFFFFF"}} />
                            </Link>
                        </Tabs>
                    </AppBar>
                </nav>
            </div>
        );
    }
}